import React, { useEffect, useState } from 'react';
import './HomePage.css';
import NavBar from '../../components/NavBar/NavBar';
import OverviewPage from '../../components/OverviewPage/OverviewPage'
import Tooltip from '@mui/material/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube, faGithub } from '@fortawesome/free-brands-svg-icons'; // Import specific icons

// Array of icons to use for bubbles
const icons = [
  [faInstagram, "https://www.instagram.com/roemus_/", "Instagram"],
  [faYoutube, "https://www.youtube.com/@roemus_5705", "Youtube"],
  [faGithub, "https://github.com/roemus", "GitHub"]
];

// Bubble component for individual bubbles
const Bubble = ({ position, icon }) => {
  return (
    <div className="bubble" style={{ left: position.left, top: position.top }}>
      <span className="bubble-text">
        <Tooltip title={icon[2]}>
          <a href={icon[1]} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={icon[0]} size="2x" />
          </a>
        </Tooltip>
      </span>
    </div>
  );
};

export default function HomePage() {
  const [bubbles, setBubbles] = useState([]);

  // Create bubbles using the icons array
  const createBubbles = () => {
    const initialBubbles = icons.map(icon => ({
      position: {
        left: `${Math.random() * 100}%`, // Random left position (0% to 40%)
        top: `${Math.random() * 50}%`, // Random top position (0% to 40%)
      },
      targetPosition: {
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 50}%`,
      },
      icon // Assign the icon from the array
    }));
    setBubbles(initialBubbles);
  };

  useEffect(() => {
    createBubbles(); // Create bubbles based on the icons array

    const interval = setInterval(() => {
      setBubbles(prevBubbles =>
        prevBubbles.map(bubble => ({
          ...bubble,
          targetPosition: {
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 50}%`
          }
        }))
      );
    }, 10000); // Change target position every 10 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  // Smoothly transition the bubbles to their target positions
  useEffect(() => {
    const transitionBubbles = setInterval(() => {
      setBubbles(prevBubbles =>
        prevBubbles.map(bubble => {
          const newLeft = parseFloat(bubble.position.left) + (parseFloat(bubble.targetPosition.left) - parseFloat(bubble.position.left)) * 0.05; // 5% of the distance
          const newTop = parseFloat(bubble.position.top) + (parseFloat(bubble.targetPosition.top) - parseFloat(bubble.position.top)) * 0.05; // 5% of the distance
          return {
            ...bubble,
            position: { left: `${newLeft}%`, top: `${newTop}%` }
          };
        }))
    }, 50); // Update position every 50 milliseconds

    return () => clearInterval(transitionBubbles); // Cleanup on unmount
  }, [bubbles]);

  return (
    <>
      <div>
        <NavBar />
      </div>
      <section className='sec-1'>
          <h1 className="head-text">Roemus_</h1>
          {/* Render all bubbles */}
          {bubbles.map((bubble, index) => (
            <Bubble key={index} position={bubble.position} icon={bubble.icon} />
          ))}
      </section>

        <OverviewPage />
    </>
  );
}
