// DarkModeToggle.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import './DarkModeToggle.css'; // Import the CSS for the button styles

const DarkModeToggle = () => {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedMode = localStorage.getItem('darkMode');
        // Standaardwaarde is true als er niets opgeslagen is
        return savedMode !== null ? savedMode === 'true' : true;
    });

    const toggleDarkMode = () => {
        setIsDarkMode(prevMode => {
            const newMode = !prevMode;
            localStorage.setItem('darkMode', newMode);
            return newMode;
        });
    };

    useEffect(() => {
        document.body.className = isDarkMode ? 'dark-mode' : 'light-mode';
    }, [isDarkMode]);

    return (
        <button className="dark-mode-toggle" onClick={toggleDarkMode}>
            <FontAwesomeIcon
                icon={isDarkMode ? faSun : faMoon}
                style={{ color: isDarkMode ? '#ffcc00' : 'white' }}
            />
        </button>
    );
};

export default DarkModeToggle;
